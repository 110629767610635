import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "../global/BackToTop.js";
import References from "../global/references.js";
import Accordions from "./Accordions.js";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

class ViralSuppressionFootnote extends Component {
  render() {
    return (
      <p
        className={`mb-1 pl-2 ${
          this.props.inAccordion ? "" : "pos-rel-left-neg-6px"
        }`}
      >
        <span className="cont-hang footnote">
          <sup className="hang" style={{ top: "5px" }}>
            &Dagger;
          </sup>
          Based on most recent viral load test. Viral suppression refers to the
          percentage of people with diagnosed HIV who have less than 200 copies
          of HIV per milliliter of blood. This is sometimes referred to as
          undetectable. Viral suppression is the ultimate goal of HIV care and
          treatment.<sup>14,22</sup>
        </span>
      </p>
    );
  }
}
class ViralSuppressionFootnote2 extends Component {
  render() {
    return (
      <p
        className={`mb-1 pl-2 ${
          this.props.inAccordion ? "" : "pos-rel-left-neg-6px"
        }`}
      >
        <span className="cont-hang footnote">
          <span className="hang">
            <sup>&Dagger;</sup>
          </span>
          Viral suppression is defined as those living with diagnosed HIV who
          had suppressed HIV viral load (&lt;200 copies/mL). This is sometimes
          referred to as undetectable. Viral suppression is the ultimate goal of
          HIV care and treatment.<sup>14,31</sup>
        </span>
      </p>
    );
  }
}
class CDCFootnote extends Component {
  render() {
    return (
      <p
        className={`mb-1 pl-2 ${
          this.props.inAccordion ? "" : "pos-rel-left-neg-6px"
        }`}
      >
        <span className="cont-hang footnote">
          <span className="hang">*</span>
          CDC notes that HIV estimates for 2020 and 2021 should be interpreted
          with caution due to adjustments made to CDC’s CD4-based depletion
          model to account for the impact of COVID-19 on HIV testing and
          diagnosis in the United States.<sup>9</sup>
        </span>
      </p>
    );
  }
}

class CDCFootnote2 extends Component {
  render() {
    return (
      <>
        <p className={`mb-1 pl-2`}>
          <span className="cont-hang footnote">
            <span className="hang">*</span>At least 1 viral load or CD4 test.
          </span>
        </p>
        <p className={`mb-1 pl-2`}>
          <span className="cont-hang footnote">
            <sup className="hang" style={{ top: "5px" }}>
              &dagger;
            </sup>
            Had 2 viral load or CD4 tests at least 3 months apart in a year.
          </span>
        </p>
      </>
    );
  }
}

class HIVCareFootNotes extends Component {
  render() {
    return (
      <>
        <p className={`mb-1 pl-2`}>
          <span className="cont-hang footnote">
            <span className="hang">*</span>Linkage to care is defined as having
            a visit with a health care provider and getting a CD4 or viral load
            test within one month of being diagnosed with HIV.
          </span>
        </p>
        <p className={`mb-1 pl-2`}>
          <span className="cont-hang footnote">
            <sup className="hang" style={{ top: "5px" }}>
              &dagger;
            </sup>
            Receipt of HIV care is defined as those living with diagnosed HIV
            who received medical care for HIV and had at least one CD4 count or
            HIV viral load test in that year.
          </span>
        </p>
      </>
    );
  }
}

class PeopleAgeFootnote extends Component {
  render() {
    return (
      <p
        className={`mb-1 pl-2 ${
          this.props.inAccordion ? "" : "pos-rel-left-neg-6px"
        }`}
      >
        <span className="cont-hang footnote">
          <span className="hang">*</span>
          Among people aged 13 and older.
        </span>
        <span className="cont-hang footnote">
          <sup className="hang" style={{ top: "5px" }}>
            &dagger;
          </sup>
          Includes infections attributed to male-to-male sexual contact and
          injection drug use (men who reported both risk factors).
        </span>
      </p>
    );
  }
}

class PeopleAgeFootnote2 extends Component {
  render() {
    return (
      <p
        className={`mb-1 pl-2 ${
          this.props.inAccordion ? "" : "pos-rel-left-neg-6px"
        }`}
      >
        <span className="cont-hang footnote">
          <span className="hang">
            <sup>§</sup>
          </span>
          Among males aged 13 and older.
        </span>
      </p>
    );
  }
}

class DataNotAvailableForChildren extends Component {
  render() {
    return (
      <p
        className={`mb-1 pl-2 ${
          this.props.inAccordion ? "" : "pos-rel-left-neg-6px"
        }`}
      >
        <span className="cont-hang footnote">
          <span className="hang">*</span>
          Data not available for children aged 12 and under.
        </span>
      </p>
    );
  }
}

class Content extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }
  closeModal = () => {
    this.setState({ showModal: false });
  };
  showModal = () => {
    if ($(window).width() <= 767) {
      this.setState({ showModal: true });
    }
  };
  scrollToSection = (section) => {
    setTimeout(() => {
      let element = $('h2[data-anchor="' + section + '"]');
      if (element.parent("button").hasClass("active")) {
        let position = element.offset().top;
        let offset = $(window).width() <= 574 ? 100 : 30;
        window.scrollTo({ top: position - offset, behavior: "smooth" });
      }
    }, 500);
  };

  render() {
    return (
      <div
        className={`
        ${this.props.show ? "d-block" : "d-none"}
        state-of-hiv
      `}
      >
        <Modal
          centered
          show={this.state.showModal}
          onHide={this.closeModal}
          className="svg-modal"
        >
          <Modal.Body className="px-0">
            <img
              className="d-block d-md-none w-100"
              src={"/annual-hiv-infections-graph-2015-2019-mobile.svg"}
              alt="Graph Showing Annual HIV Infections in the US, 2015- 2019"
            />
          </Modal.Body>
        </Modal>
        <Container
          fluid
          className="hero state-of-hiv"
          role="img"
          aria-label="Group of people standing
                      close together"
        />
        <Container>
          <Row>
            <Col xs={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                
                <h1 className="title">
                  <span className="sub-title d-block">State of the HIV Epidemic</span>
                  SUBSTANTIAL PROGRESS <br className="d-block d-md-none" />
                  AND <br className="d-none d-md-block" />
                  THE CHALLENGES THAT REMAIN
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={10} className="m-auto px-0">
              <Container>
                <Row>
                  <Col xs={12} className="p-0 pt-2">
                    <p>
                      In the 40 years since the beginning of the HIV epidemic, the United States has been able to make incredible strides in HIV prevention, treatment, and care.<sup>1</sup> HIV was once the leading cause of death for young people, but because of scientific advances, fewer people are acquiring HIV, and those who do are living longer and healthier lives.<sup>2-5</sup> Despite the uptake of pre-exposure prophylaxis (PrEP) medicine being slow&mdash;with too many people remaining unaware of their HIV status&mdash;the rate of estimated new HIV infections has declined 75% between 1984 and 2021.<sup>6-10,</sup>*
                    </p>
                    <p>
                      Progress has been made to bring the epidemic to its current state, but there is still work to be done. 
                    </p>
                    <div className="white-box">
                      <ul>
                        <li>
                          In <strong>2021</strong>, there were approximately{" "}
                          <strong>1.2 million</strong> people aged 13 and older
                          living with HIV in the US<sup>9</sup>
                        </li>
                        <li>
                          In <strong>2021</strong>, there were an estimated{" "}
                          <strong>32,100 new HIV infections</strong> in the US,
                          which represented a <strong>12% decrease</strong>{" "}
                          since 2017<sup>9,</sup>*
                        </li>
                        <li>
                          In <strong>2021</strong>, an estimated{" "}
                          <strong>1 in 8</strong> people living with HIV in the
                          US did not know they had it<sup>11</sup>
                        </li>
                        <li>
                          In <strong>2021</strong>, for every{" "}
                          <strong>100</strong> people diagnosed with HIV, about{" "}
                          <strong>75%</strong> received some HIV care,<sup>†</sup>{" "}
                          <strong>54%</strong> were retained in care,<sup>‡</sup> and{" "}
                          <strong>66%</strong> were virally suppressed
                          <sup>9,§</sup>
                        </li>
                        <li>
                          In <strong>2022</strong>, an estimated{" "}
                          <strong>1.2 million</strong> people in the US could
                          benefit from PrEP, but only <strong>382,364</strong>{" "}
                          individuals filled prescriptions<sup>12</sup>
                        </li>
                      </ul>
                      <div className="chart-bg">
                        <div onClick={this.showModal} className="cont-chart">
                          <h3>
                            Estimated Annual HIV{" "}
                            <br className="d-block d-md-none" />
                            Infections in the{" "}
                            <br className="d-block d-md-none" />
                            US, 2015-2021
                          </h3>
                          <img
                            className="d-none d-md-block w-100"
                            src={"/annual-hiv-infections-graph-2015-2019.svg"}
                            alt="Graph Showing Annual HIV Infections in the US, 2015- 2019"
                          />
                          <img
                            className="d-block d-md-none p-2 w-100"
                            src={"/annual-hiv-infections-graph-2015-2019-mobile.svg"}
                            alt="Graph Showing Annual HIV Infections in the US, 2015- 2019"
                          />
                        </div>
                      </div>
                      <ul className="footnote-list">
                        <li>
                          Sources: Centers for Disease Control and Prevention. Estimated HIV incidence and prevalence in the United States, 2015–2019. HIV Surveillance Supplemental Report 2021;26(No. 1). <a target="_blank"
                          href="https://stacks.cdc.gov/view/cdc/112160">
                          <em>https://stacks.cdc.gov/view/cdc/112160</em></a>. Published May 2021. Accessed October 17, 2024. Centers for Disease Control and Prevention. NCHHSTP AtlasPlus. <a target="_blank"
                          href="https://gis.cdc.gov/grasp/nchhstpatlas/main.html">
                          <em>https://gis.cdc.gov/grasp/nchhstpatlas/main.html</em></a>. Accessed January 24, 2024.
                        </li>
                      </ul>
                    </div>
                    <p>
                      Despite the advancements in HIV prevention and care,
                      <sup>4</sup> the state of the epidemic today reminds us of
                      the role stigma and discrimination play in increasing
                      risk. There are many social, economic, and structural
                      barriers that continue to prevent some people from
                      accessing the care they need.<sup>13</sup>
                    </p>
                    <p>
                      As a result, progress has been unevenly distributed, with
                      already marginalized groups continuing to bear the brunt
                      of the HIV epidemic.<sup>8</sup> These widening
                      disparities can be seen when looking at the data below,
                      which show rates of new infections, treatment, and death
                      among those groups most impacted by HIV.
                    </p>
                    <ul className="footnote-list">
                      <li>
                      <span className="sup">*</span>CDC notes that HIV estimates for 2020 and 2021 should be interpreted with caution due to adjustments made to CDC’s CD4-based depletion model to account for the impact of COVID-19 on HIV testing and diagnosis in the United States.<sup>9</sup>
                      </li>
                      <li>
                        <sup className="sup">&dagger;</sup>At least 1 viral load or CD4 test.<sup>14</sup>
                      </li>
                      <li>
                        <sup className="sup">&Dagger;</sup>Had 2 viral load or CD4 tests at least 3 months apart.<sup>14</sup>
                      </li>
                      <li>
                        <sup className="sup">§</sup>Based on most recent viral load test. Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>15</sup>
                      </li>
                    </ul>
                    
                    <Accordions
                      scrollToSection={this.scrollToSection}
                      ViralSuppressionFootnote={
                        <ViralSuppressionFootnote inAccordion={true} />
                      }
                      ViralSuppressionFootnote2={
                        <ViralSuppressionFootnote2 inAccordion={true} />
                      }
                      PeopleAgeFootnote={
                        <PeopleAgeFootnote inAccordion={true} />
                      }
                      PeopleAgeFootnote2={
                        <PeopleAgeFootnote2 inAccordion={true} />
                      }
                      DataNotAvailableForChildren={
                        <DataNotAvailableForChildren inAccordion={true} />
                      }
                      CDCFootnote2={<CDCFootnote2 inAccordoin={true} />}
                      HIVCareFootNotes={<HIVCareFootNotes inAccordoin={true} />}
                    />
                    <h2 className="title">MOVING FORWARD, TOGETHER</h2>
                    <Container>
                      <Row>
                        <Col xs={12} className="px-0">
                          <p>
                            HIV has gone from a death sentence to a manageable
                            chronic condition with treatment options.
                            <sup>5,54</sup> But, as the data show, access to
                            these prevention and treatment options is not always
                            evenly distributed.<sup>55</sup> Moving forward, we must collectively focus on ways to lessen the burden of HIV on marginalized communities and recognize that doing so will take working together to overcome the societal barriers that prevent too many members of these communities from accessing the care they need.
                          </p>
                        </Col>
                        <Col xs={12} className="px-0">
                          <BackToTop />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <References page={this.props.page} />
      </div>
    );
  }
}

export default Content;
