import * as React from "react";
import { Component } from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useContext } from "react";

class Accordions extends Component {
  constructor() {
    super();
  }
  render() {
    const ContextAwareToggle = ({ children, eventKey, callback }) => {
      const currentEventKey = useContext(AccordionContext);
      const isCurrentEventKey = currentEventKey === eventKey;
      const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
      );
      return (
        <button
          type="button"
          className={`${isCurrentEventKey ? "active" : ""}`}
          onClick={decoratedOnClick}
        >
          {children}
        </button>
      );
    };

    return (
      <>
        <Accordion className="body mb-standard" defaultActiveKey="0">
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="0"
                callback={() => this.props.scrollToSection("gay")}
              >
                <h2 data-anchor="gay" className="title">
                  Gay, Bisexual, and Other Men Who Have Sex with Men
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p>
                  Men who have sex with men (MSM) have been the population most
                  impacted by the epidemic since the beginning.<sup>16</sup>
                  &nbsp;MSM of color are even more disproportionately impacted
                  by HIV, as issues of stigma and discrimination on the basis of
                  sexual orientation intersect with racism and other social
                  determinants of health. In the US, it is estimated that 1 in 6
                  MSM will be infected with HIV at some point in their lives.
                  <sup>17</sup>
                </p>
                <div className="white-box">
                  <h3 className="text-normal-case">According to the CDC:</h3>
                  <ul>
                    <li>
                      MSM make up an estimated <strong>2%</strong> of the US
                      population but accounted for <strong>70%</strong> of the
                      estimated new HIV infections in 2021<sup>18</sup>
                    </li>
                    <li>
                      In 2022, gay and bisexual men ages 13 to 34 made up <strong>~63%</strong> of new HIV diagnoses among all gay and bisexual men<sup>19</sup>
                    </li>
                    <li>
                      As of 2022, an estimated <strong>1 in 7</strong> MSM
                      living with HIV was unaware of their status<sup>19</sup>
                    </li>
                    <li>
                      From <strong>2008</strong> to <strong>2019</strong>, Black
                      MSM and Hispanic/Latino MSM experienced a{" "}
                      <strong>2%</strong>
                      &nbsp;decrease and <strong>18%</strong> increase,
                      respectively, in new HIV diagnoses, compared to a{" "}
                      <strong>34%</strong> decrease among White MSM
                      <sup>20</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart">
                      <h3>
                        <span>
                          New HIV <br className="d-block d-md-none" />
                          Diagnoses Among <br className="d-block d-md-none" />
                          GAY AND BISEXUAL MEN in the US <br className="d-none d-lg-block" />AND DEPENDENT AREAS by
                          Race/Ethnicity, 2021*<sup>,&dagger;</sup>
                        </span>
                      </h3>
                      <img
                        className="d-none d-md-block w-100 p-4"
                        src={"/new-hiv-diagnoses-by-race.svg"}
                        alt="Graph Showing New HIV Diagnoses Among MSM in the US by Race/Ethnicity, 2019"
                      />
                      <img
                        className="d-block d-md-none p-2 w-100"
                        src={"/new-hiv-diagnoses-by-race-mobile.svg"}
                        alt="Graph Showing New HIV Diagnoses Among MSM in the US by Race/Ethnicity, 2019"
                      />
                    </div>
                  </div>
                  <ul className="footnote-list">
                      <li>
                        Source: CDC. Diagnoses of HIV infection in the United States and dependent areas, 2021. <a target="_blank" href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-32.pdf"><em>HIV Surveillance Report</em></a>. 2023;34.
                      </li>
                  </ul>
                </div>
                <ul className="footnote-list">
                    <li>
                    <span className="sup">*</span>Among people aged 13 and older.
                    </li>
                    <li>
                      <sup className="sup">&dagger;</sup>Includes infections attributed to male-to-male sexual contact and injection drug use (men who reported both risk factors).
                    </li>
                  </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="1"
                callback={() => this.props.scrollToSection("black")}
              >
                <h2 data-anchor="black" className="title">
                  Black/African Americans
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p>
                  Black Americans in this country deal with structural racism
                  and inequality that impacts every aspect of their lives from
                  economic stability to education, housing, food security, and
                  healthcare.<sup>13</sup> Together, this impact may leave
                  individuals at increased risk for HIV and make them less
                  likely to receive the care they need.<sup>4,21</sup> It is not
                  surprising, therefore, that survival after an AIDS diagnosis
                  is lower for Black Americans than for most other racial/ethnic
                  groups or that Black Americans have had the highest
                  age-adjusted death rate due to HIV disease throughout most of
                  the epidemic.<sup>4</sup>
                </p>
                <div className="white-box">
                  <ul>
                    <li>
                      In <strong>2021</strong>, Black/African American people
                      made up&nbsp;
                      <strong>12%</strong> of the US population but accounted
                      for&nbsp;
                      <strong>40%</strong> of new HIV infections<sup>22</sup>
                    </li>
                    <li>
                      As of <strong>2021</strong>, an estimated&nbsp;
                      <strong>40%</strong> of people living with HIV in the US
                      were Black/African American<sup>22</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, Black/African American people
                      had the highest rate of new HIV infections at{" "}
                      <strong>37</strong> infections per{" "}
                      <strong>100,000</strong> people, which was more than{" "}
                      <strong>7</strong> times the rate for White people
                      <sup>22</sup>
                    </li>
                    <li>
                      In <strong>2022</strong>, only <strong>11%</strong> of
                      Black/African American people who were eligible for PrEP
                      were prescribed it in the US, compared to&nbsp;
                      <strong>82%</strong> of eligible White people<sup>12</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, for every <strong>100</strong>{" "}
                      Black/African American people living with diagnosed HIV in
                      the US, <strong>73 </strong> received some HIV care,*{" "}
                      <strong>52</strong> were retained in care,
                      <sup>&dagger;</sup> and <strong>62</strong> were virally
                      suppressed.<sup>&Dagger;</sup> In comparison, for every{" "}
                      <strong>100</strong> people overall living with diagnosed
                      HIV,&nbsp;
                      <strong>75</strong> received some care,{" "}
                      <strong>54</strong> were retained in care, and{" "}
                      <strong>66</strong> were virally suppressed
                      <sup>14,§</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart black">
                      <h3>
                        <span>
                          New HIV <br className="d-block d-md-none" />
                          DIAGNOSES IN THE US AND DEPENDENT AREAS, 2021
                          <sup>&dagger;</sup>
                        </span>
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img p-md-3">
                          <img
                            className="d-none d-md-block w-100"
                            src={"/new-hiv-diagnoses-black-americans.svg"}
                            alt="Pie Chart Showing New HIV Diagnoses in the US For Black Americans, 2018"
                          />
                          <img
                            className="d-block d-md-none p-3 w-100"
                            src={
                              "/new-hiv-diagnoses-black-americans-mobile.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US For Black Americans, 2018"
                          />
                        </div>
                        <p className="chart-text">
                          BLACK/AFRICAN AMERICANS Accounted&nbsp;For&nbsp;
                          <br className="d-none d-md-block" />
                          <span className="font-red d-inline-block font-heavy">
                            40%
                          </span>{" "}
                          of new <br className="d-none d-md-block" />
                          HIV diagnoses in{" "}
                          <span className="font-red font-heavy">2021</span>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <ul className="footnote-list">
                    <li>
                      Source: CDC. Diagnoses of HIV infection in the United States and dependent areas, 2021. <a target="_blank" href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-31.pdf"><em>
                        HIV Surveillance Report</em></a>. 2023;34.
                    </li>
                  </ul>
                </div>
                <ul className="footnote-list">
                    <li>
                    <span className="sup">*</span>At least 1 viral load or CD4 test.
                    </li>
                    <li>
                      <sup className="sup">&dagger;</sup>Had 2 viral load or CD4 tests at least 3 months apart in a year.
                    </li>
                    <li>
                      <sup className="sup">&Dagger;</sup>Based on most recent viral load test. Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>15</sup>
                    </li>
                    <li>
                      <sup className="sup">§</sup>Among people aged 13 and older.
                    </li>
                  </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="2"
                callback={() => this.props.scrollToSection("hispanic")}
              >
                <h2 data-anchor="hispanic" className="title">
                  Hispanic/Latino <br className="d-block d-md-none" />
                  People
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <p>
                  Hispanic/Latino American communities deal with
                  disproportionate poverty, lower educational levels, and
                  language barriers that can make it harder for people to access
                  the care and services they need. Hispanic Americans are also
                  more likely to be uninsured than any other community.
                  Moreover, some Hispanic/Latino Americans may not utilize the
                  HIV prevention and care services in their area out of fear of
                  disclosing their immigration status.<sup>18,19</sup>
                </p>
                <div className="white-box">
                  <ul>
                    <li>
                      In <strong>2021</strong>, Hispanic/Latino people made up{" "}
                      <strong>18%</strong> of the US population but accounted
                      for <strong>29%</strong> of HIV infections
                      <sup>22</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, Hispanic/Latino people in the US
                      had the second highest rate of new HIV infections at{" "}
                      <strong>19</strong> infections per{" "}
                      <strong>100,000</strong> people, which was{" "}
                      <strong>4</strong> times the rate for White people
                      <sup>22</sup>
                    </li>
                    <li>
                      Between <strong>2010</strong> and <strong>2022</strong>, the estimated number of new HIV infections for Hispanic/Latino people in the US went up by <strong>12%</strong>, while the estimated number overall went down <strong>19%</strong><sup>23,</sup>*
                    </li>
                    <li>
                      In <strong>2022</strong>, Latino MSM (men who have sex with men) accounted for more estimated new HIV infections than Black/African American or White MSM, with an estimated HIV incidence of 8300<sup>23,</sup>*
                    </li>
                    <li>
                      As of <strong>2019</strong>, approximately{" "}
                      <strong>1 in 6</strong> Hispanic/Latino people living with
                      HIV in the US was unaware of their status
                      <sup>24</sup>
                    </li>
                    <li>
                      In <strong>2022</strong>, <strong>54%</strong> of Latinos living with HIV were not retained for HIV care, <strong>46%</strong> were not virally suppressed at some point (in <strong>2022</strong>), and <strong>16%</strong> of Latinos living with HIV were unaware of their status<sup>25,</sup>*<sup>,&dagger;</sup>
                    </li>
                    <li>
                      In <strong>2022</strong>, only <strong>21%</strong> of
                      Hispanic/Latino people who were eligible for PrEP were
                      prescribed it, compared to <strong>82%</strong> of
                      eligible White people<sup>12</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart hispanic">
                      <h3>
                        <span>
                          New hiv Diagnoses Among Hispanic/Latino males in the
                          us <br className="d-none d-md-block" />by Transmission Category, 2021<sup>&Dagger;</sup>
                        </span>
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block w-100 mb-3"
                            src={
                              "/new-hiv-diagnoses-hispanic-latino-americans.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US For Hispanic/Latino Americans, 2018"
                          />
                          <img
                            className="d-block d-md-none w-100"
                            src={
                              "/new-hiv-diagnoses-hispanic-latino-americans-mobile.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US For Hispanic/Latino Americans, 2018"
                          />
                        </div>
                        <p className="chart-text">
                          The Majority of new{" "}
                          <br className="d-none d-md-block" />
                          hiv diagnoses <br className="d-block d-md-none" />
                          among Hispanic/Latino males in 2021 were through{" "}
                          <span className="font-red">
                            male-to-male sexual contact
                          </span>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  <ul className="footnote-list">
                    <li>
                      Source: Centers for Disease Control and Prevention. NCHHSTP AtlasPlus. <em><a
                        target="_blank" href="https://www.cdc.gov/nchhstp/atlas/index.htm.">https://www.cdc.gov/nchhstp/atlas/index.htm</a></em>. Accessed January 22, 2024.
                    </li>
                  </ul>
                </div>
                <ul className="footnote-list">
                    <li>
                    <span className="sup">*</span>Ages 13 and older.
                    </li>
                    
                    <li>
						          <sup className="sup">&dagger;</sup>Based on most recent viral load test. Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>15</sup>
                    </li>
                    <li>
						          <sup className="sup">&Dagger;</sup>Among males aged 13 and older.
                    </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="3"
                callback={() => this.props.scrollToSection("transgender")}
              >
                <h2 data-anchor="transgender" className="title">
                  Transgender People
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <p>
                  Data have not historically captured transgender people, so
                  there is still not a complete understanding of HIV among this
                  group.<sup>26,27</sup> Data across many fields show that
                  transphobia impacts all aspects of life for transmen and
                  transwomen, from housing and employment to health and safety
                  and puts them at increased risk for HIV.<sup>26</sup>{" "}
                  Transgender people also face high rates of violence including
                  sexual assault, which can further increase the HIV risk, and
                  are less likely to receive hospital care, including
                  postexposure prophylaxis, after an assault.<sup>28</sup>
                </p>
                <div className="white-box">
                  <ul>
                    <li>
                      In <strong>2021</strong>, transgender people accounted for{" "}
                      <strong>2%</strong> of new HIV diagnoses in the US<sup>9</sup>
                    </li>
                    <li>
                      A study of trans women in <strong>7</strong> major US
                      cities from <strong>2019</strong> to <strong>2020</strong>{" "}
                      found that <strong>42%</strong> of those interviewed were
                      living with HIV<sup>29</sup>
                    </li>
                    <li>
                      According to a systematic review and meta-analysis of
                      international studies among 48,604 transfeminine
                      individuals between <strong>2000</strong> and{" "}
                      <strong>2019</strong>, they were <strong>66</strong> times
                      more likely to be living with HIV compared to the general
                      population over <strong>15</strong> years of age
                      <sup>30</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, among the transgender people
                      newly diagnosed with HIV in the US, <strong>45%</strong>{" "}
                      were Black/African American and <strong>31%</strong> were
                      Hispanic/Latino<sup>9</sup>
                    </li>
                  </ul>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="4"
                callback={() => this.props.scrollToSection("women")}
              >
                <h2 data-anchor="women" className="title">
                  Women
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <p>
                  In the US, women accounted for approximately 1 in 4 people
                  living with HIV in 2021, yet the narrative of the HIV epidemic
                  often excludes their stories.<sup>31,32</sup> Biological
                  factors (heterosexual sex is more likely to transmit HIV to a
                  woman from her male partner than vice versa) and social
                  factors (including gender discrimination and barriers to
                  accessing reproductive care) put women at increased risk of
                  HIV.
                  <sup>33,34</sup> In addition, women are more likely to
                  experience intimate partner violence, which also increases
                  their HIV risk. Women of color are further disproportionately
                  impacted by HIV as they experience stigma and discrimination
                  based on both gender and race.<sup>35</sup>
                </p>
                <div className="white-box">
                  <ul>
                    <li>
                      In <strong>2021</strong>, <strong>18%</strong> of new HIV
                      diagnoses were among women
                      <sup>9</sup>
                    </li>
                    <li>
                      The majority of new HIV diagnoses among women{" "}
                      <strong>(83%)</strong> in <strong>2021</strong> were
                      attributed to heterosexual contact, while another{" "}
                      <strong>16%</strong> were attributed to injection drug use
                      <sup>31</sup>
                    </li>
                    <li>
                      In 2021, <strong>82%</strong> of women diagnosed with HIV
                      were linked to HIV care,* <strong>75%</strong> received
                      some care,<sup>&dagger;</sup> and <strong>65%</strong>{" "}
                      were virally suppressed.<sup>&Dagger;</sup> In comparison,{" "}
                      <strong>82%</strong> of people diagnosed with HIV overall
                      were linked to HIV care, <strong>75%</strong> received
                      some care, and <strong>66%</strong> were virally
                      suppressed
                      <sup>31</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, Black/African American females
                      made up only <strong>13%</strong> of the US female
                      population aged <strong>13</strong> and older and
                      accounted for over half <strong>(54%)</strong> of new HIV
                      diagnoses among this group. In comparison, White females
                      accounted for <strong>23%</strong> and Hispanic/Latina
                      females accounted for <strong>18%</strong> of new HIV
                      diagnoses
                      <sup>36,§</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, the rate of Black/African
                      American females living with an HIV diagnosis was{" "}
                      <strong>17</strong> times that of White females
                      <sup>31</sup>
                    </li>
                    <li>
                      Although annual HIV infections remained stable overall
                      among Black/African American women from{" "}
                      <strong>2017</strong> to <strong>2021</strong>, the rate
                      of new HIV infections among Black/African American women
                      is <strong>10</strong> times that of White women and{" "}
                      <strong>4</strong> times that of Hispanic/Latina women
                      <sup>17</sup>
                    </li>
                    <li>
                      In <strong>2022</strong>, less than <strong>12%</strong>{" "}
                      of women in the US who were eligible for PrEP medicine
                      were prescribed the preventative regimen
                      <sup>12</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart women">
                      <h3>
                        <span>
                          New HIV <br className="d-block d-md-none" />
                          Diagnoses Among Females by Race/Ethnicity in the US
                          and dependent areas, 2021
                          <sup>&#167;</sup>
                        </span>
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block px-4 pt-4 pb-2"
                            src={"/new-hiv-diagnoses-among-women.svg"}
                            alt="Bar Graph Showing New HIV Diagnoses in the US For Women By Race/Ethnicity, 2018"
                          />
                          <img
                            className="d-block d-md-none p-2 w-100"
                            src={"/new-hiv-diagnoses-among-women-mobile.svg"}
                            alt="Bar Graph Showing New HIV Diagnoses in the US For Women By Race/Ethnicity, 2018"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="footnote-list">
                    <li>
                      Source: CDC. Diagnoses of HIV Infection in the United States and Dependent Areas 2021: Special Focus Profiles. <a target="_blank" href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-31.pdf"><em>HIV Surveillance Report</em></a>. 2023;34.
                    </li>
                  </ul>
                </div>
                <ul className="footnote-list">
                    <li>
                    <span className="sup">*</span>AIDSVu defines linkage to care as having a visit with a healthcare provider and getting a CD4 or viral load test within one month of being diagnosed with HIV.
                    </li>                   
                    <li>
						          <sup className="sup">&dagger;</sup>Receipt of HIV care is defined as those living with diagnosed HIV who received medical care for HIV and had at least one CD4 count or HIV viral load test in that year.
                    </li>
                    <li>
						          <sup className="sup">&Dagger;</sup>Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>15</sup>
                    </li>
                    <li>
                      <sup className="sup">§</sup>Among females aged 13 and older.
                    </li>
                  </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="5"
                callback={() => this.props.scrollToSection("young")}
              >
                <h2 data-anchor="young" className="title">
                  Young People
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <p>
                  Young people ages 13 to 24 are among those most affected by
                  HIV as a result of numerous social, biological, and behavioral
                  factors.<sup>37,38</sup> The CDC estimated that youth aged
                  15-24 accounted for almost half of the 26 million new sexually
                  transmitted infections (STIs) that occurred in the United
                  States in 2018, and having certain kinds of STIs can increase
                  the risk of acquiring HIV.<sup>39,40</sup> Young people may
                  also have difficulty gaining access to HIV testing, treatment,
                  and prevention for HIV and other STIs because of lack of
                  transportation, inability to pay, or privacy concerns.
                  <sup>37,41</sup> Young people who are members of other
                  marginalized communities—including young Black American men
                  and young MSM—face increased stigma and higher risk.
                  <sup>4,20</sup>
                </p>
                <div className="white-box">
                  <ul>
                    <li>
                      In <strong>2021</strong>, <strong>19%</strong> of
                      estimated new HIV infections in the US were among young
                      people <strong>aged 13 to 24</strong><sup>42</sup>
                    </li>
                    <li>
                      Young people in the US are less likely to know their HIV
                      status. As of <strong>2021</strong>, for every{" "}
                      <strong>100</strong> young people aged{" "}
                      <strong>13 to 24</strong> living with HIV, only{" "}
                      <strong>56</strong> knew their status. In comparison, for
                      every <strong>100</strong> people of all ages living with
                      HIV, <strong>87</strong> knew their status<sup>43,</sup>*
                    </li>
                    <li>
                      In <strong>2021</strong>, for every <strong>100</strong>{" "}
                      people aged <strong>13 to 24</strong> with diagnosed HIV,{" "}
                      <strong>80</strong> received some care,<sup>&dagger;</sup>{" "}
                      <strong>55</strong> were retained in care,
                      <sup>&Dagger;</sup> and <strong>65</strong> were virally
                      suppressed.<sup>§</sup> In comparison, for every{" "}
                      <strong>100</strong> people of all ages with diagnosed
                      HIV, <strong>75</strong> received some care,{" "}
                      <strong>54</strong> were retained in care, and{" "}
                      <strong>66</strong> were virally suppressed<sup>44</sup>
                    </li>
                    <li>
                      According to the CDC, only <strong>20%</strong> of young
                      people aged <strong>16 to 24</strong> in the US who could
                      benefit from PrEP medicine were prescribed the prevention
                      regimen in <strong>2021</strong>
                      <sup>45</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart young">
                      <h3>
                        {/*New HIV <br className="d-block d-md-none" />Diagnoses in the US, 2018*/}
                        New HIV <br className="d-block d-md-none" />
                        Diagnoses Among Young People in{" "}
                        <br className="d-block d-md-none" />
                        the US <br className="d-none d-lg-block" />
                        and dependent areas, 2021
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block w-100"
                            src={"/new-hiv-diagnoses-among-young-people.svg"}
                            alt="Pie Chart Showing New HIV Diagnoses in the US Among Young People, 2018"
                          />
                          <img
                            className="d-block d-md-none w-100"
                            src={
                              "/new-hiv-diagnoses-among-young-people-mobile.svg"
                            }
                            alt="Pie Chart Showing New HIV Diagnoses in the US Among Young People, 2018"
                          />
                        </div>
                        <p className="chart-text w-md-44">
                          Young people ages{" "}
                          <span className="font-red">13 to 24</span> accounted
                          for <span className="font-red">19%</span> of new HIV
                          diagnoses in <span className="font-red">2021</span>.
                        </p>
                      </div>
                    </div>
                  </div>
                  <ul className="footnote-list">
                    <li>
                      Source: CDC.  Diagnoses of HIV infection in the United States and dependent areas, 2021. <a target="_blank" href="https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-report-2018-updated-vol-31.pdf"><em>
                        HIV Surveillance Report</em></a>. 2023;34.
                    </li>
                  </ul>
                </div>
                <ul className="footnote-list">
                    <li>
                    <span className="sup">*</span>Data not available for children aged 12 and under.
                    </li>
                    <li>
						          <sup className="sup">&dagger;</sup>At least 1 viral load or CD4 test.
                    </li>
                    <li>
						          <sup className="sup">&Dagger;</sup>Had 2 viral load or CD4 tests at least 3 months apart in a year.
                    </li>
                    <li>
						          <sup className="sup">§</sup>Based on most recent viral load test. Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>15</sup>
                    </li>
                  </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey="6"
                callback={() => this.props.scrollToSection("south")}
              >
                <h2 data-anchor="south" className="title">
                  The Southern US
                </h2>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                <p>
                  HIV is more prevalent in the South, where the epidemic is
                  largely driven by poverty, racism, and lack of access to
                  healthcare. The South has the highest poverty rate, lowest
                  median household income, and greatest percentage of uninsured
                  people (ie, nearly half of Americans without insurance live in
                  the South) in the country.<sup>46,47</sup> Yet, fewer people
                  in the South are aware they have HIV, which means they do not
                  access care or reach viral suppression.* In addition,
                  non-urban areas in the South are bearing the brunt of the
                  nation’s opioid crisis, which puts residents at additional risk
                  of HIV. The HIV epidemic in the South illustrates the
                  increased risk among those who identify with more than one
                  marginalized group as HIV is more likely among Black Americans
                  in the South and particularly among Black American MSM.
                  <sup>46</sup>
                </p>
                <div className="white-box">
                  <ul>
                    <li>
                      In <strong>2021</strong>, more than half{" "}
                      <strong>(52%)</strong> of all new HIV diagnoses occurred
                      in people living in the South even though only{" "}
                      <strong>38%</strong> of the US population lives in this
                      region<sup>48</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, the rate of HIV diagnoses among
                      people in the South was <strong>2.1</strong> times the
                      rate among people in the Midwest<sup>49</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, the South had a greater number
                      of new HIV diagnoses than all other regions combined
                      <sup>9</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, the South accounted for{" "}
                      <strong>48%</strong> of deaths among people with diagnosed
                      HIV infection
                      <sup>49</sup>
                    </li>
                    <li>
                      In <strong>2022</strong>, Southerners accounted for only{" "}
                      <strong>38%</strong> of PrEP medicine users
                      <sup>48</sup>
                    </li>
                    <li>
                      Black/African American people aged 13 and older accounted
                      for <strong>49%</strong> of new HIV diagnoses in the
                      region in <strong>2021</strong>
                      <sup>36</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, Black/African American
                      male-to-male sexual contact accounted for the largest
                      percentage of new HIV diagnoses <strong>(46%)</strong> in
                      the South
                      <sup>36</sup>
                    </li>
                    <li>
                      New HIV diagnoses among Hispanic/Latino MSM in the South
                      increased <strong>37%</strong> between{" "}
                      <strong>2012</strong> and <strong>2019</strong> while they
                      decreased by <strong>23%</strong> for White MSM in the
                      region during that same time period<sup>50,51</sup>
                    </li>
                    <li>
                      In <strong>2019</strong>, among people living with HIV,
                      the highest percentage of people living with undiagnosed
                      HIV infection was in the Midwest <strong>(14.9%)</strong>{" "}
                      and the South <strong>(14.8%)</strong>, followed by the
                      West <strong>(13.9%)</strong> and Northeast{" "}
                      <strong>(8.6%)</strong>
                      <sup>52</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, <strong>82%</strong> of new HIV
                      diagnoses among men in the South were transmitted through
                      male-to-male sexual contact, <strong>10%</strong> through
                      heterosexual contact, <strong>4%</strong> through
                      injection drug use, and <strong>4%</strong> through
                      injection drug use and male-to-male sexual contact
                      <sup>53</sup>
                    </li>
                    <li>
                      In <strong>2021</strong>, <strong>86%</strong> of new HIV
                      diagnoses among women in the South were transmitted
                      through heterosexual contact and <strong>13%</strong>{" "}
                      through injection drug use<sup>53</sup>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart south">
                      <h3>
                        Rates of People Living with HIV,{" "}
                        <br className="d-block d-md-none" />
                        2021
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block"
                            src={"/rates-of-people-living-with-hiv.svg"}
                            alt="Map of the US Showing Rates of People Living With HIV, 2018"
                          />
                          <img
                            className="d-block d-md-none w-100"
                            src={"/rates-of-people-living-with-hiv-mobile.svg"}
                            alt="Map of the US Showing Rates of People Living With HIV, 2018"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="footnote-list">
                    <li>
                      Source: AIDSVu. Rates of people living with HIV, 2021. Local Data: United States. Map data available through <em>J Med Internet Res</em>, 2020. <a
                      target="_blank" href="https://aidsvu.org/local-data/united-states/">
                      <span>
                        https://aidsvu.org/local-data/united-states/
                      </span></a>
                    </li>
                  </ul>
                  <div className="chart-bg">
                    <div className="cont-chart south">
                      <h3>
                        Rates of People <br className="d-block d-md-none" />
                        Newly Diagnosed <br className="d-block d-md-none" />
                        with HIV, 2021
                      </h3>
                      <div className="chart-area">
                        <div className="cont-img">
                          <img
                            className="d-none d-md-block"
                            src={
                              "/rates-of-people-newly-diagnosed-with-hiv.svg"
                            }
                            alt="Map of the US Showing Rates of People Newly Diagnosed With HIV, 2018"
                          />
                          <img
                            className="d-block d-md-none w-100"
                            src={
                              "/rates-of-people-newly-diagnosed-with-hiv-mobile.svg"
                            }
                            alt="Map of the US Showing Rates of People Newly Diagnosed With HIV, 2018"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="footnote-list">
                    <li>
                      Source: AIDSVu. Rates of people newly diagnosed with HIV, 2021. View the Map. Interactive map data available through <em>J Med Internet Res</em>, 2020. <a target="_blank" href="https://map.aidsvu.org/map">
                      <span>
                        https://map.aidsvu.org/map
                      </span></a>
                    </li>
                  </ul>
                </div>
                <ul className="footnote-list">
                    <li>
						          <sup className="sup">*</sup>Based on most recent viral load test. Viral suppression is defined as having less than 200 copies of HIV per milliliter of blood.<sup>15</sup>
                    </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }
}

export default Accordions;
